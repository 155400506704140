import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FormData } from '../helpers/FormData';
import { _senderUpsert } from '../services/apiConnections.service';

export const SenderUpsert = (props) => {

    const [error, setError] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        name: ''
    });
    const [formErrors, setFormErrors] = useState({});

    let navigate = useNavigate();
    let params = useParams();

    const handleUpsert = async (event) => {
        event.preventDefault();
        let result;

        result = await _senderUpsert(formValues, props.edit ? params.id : '');

        if (result.error) {
            setError(result.error);
        } else {
            console.log("success");
            navigate('/senders');
        }
    };

    const handleValidation = (target) => {
        switch (target.name) {
            case 'name':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        name: "Cannot be empty"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['name'];
                    setFormErrors(copy);
                }
                break;

            default: break;
        }
    };

    const formIsValid = () => {
        return !Object.keys(formErrors).length && formValues.name.length;
    };

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>{!props.edit ? 'Create Sender' : 'Edit Sender'}</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='pageContent'>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='name'>Name</label>
                        <div className='inputWrapper'>
                            <input
                                id='name'
                                name='name'
                                type="text"
                                className={`formInput ${formErrors.name ? 'invalidInput' : ''}`}
                                placeholder="Name Sender"
                                value={formValues.name}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'name',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.name}</span>
                        </div>
                    </div>
                    <div className='buttonBlock'>
                        <button
                            style={{'marginRight': '10px'}}
                            onClick={() => { navigate('/senders') }}
                            className={`mainBtn btn`}>Cancel</button>
                        <button
                            onClick={handleUpsert}
                            className={`mainBtn btn ${formIsValid() ? '' : 'disabledBtn'}`}
                            disabled={!formIsValid()}>{props.edit ? 'Save' : 'Create'}</button>
                    </div>
                </div>
            </div>
        </div >
    );
};